<template>
  <div>
    <!-- Inputs de data -->
    <b-row>
      <b-col cols="6">
        <b-form-group label="Data Inicial">
          <b-form-input
            v-model="start_date"
            @input="getAll"
            type="date"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Data Final">
          <b-form-input
            v-model="end_date"
            @input="getAll"
            type="date"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-card :title="`Painel de Usuário `" class="text-left">
      <b-row class="d-flex justify-content-around">
        <div class="dashboard-item text-center">
          <strong>{{ `${item.hours}h` }}</strong>
          <small>Horas no período </small>
        </div>

        <!-- Custo Direto -->

        <div class="dashboard-item text-center">
          <strong>{{ `R$ ${numberToMonetary(item.directCost)}` }}</strong>
          <small>Custo Direto </small>
        </div>

        <!-- Custo Indireto -->

        <div class="dashboard-item text-center">
          <strong>{{ `R$ ${numberToMonetary(item.indirectCost)}` }}</strong>
          <small>Custo Indireto </small>
        </div>

        <!-- Custo Total -->

        <div class="dashboard-item text-center">
          <strong>{{ `R$ ${numberToMonetary(item.totalCost)}` }}</strong>
          <small>Custo Total </small>
        </div>
      </b-row>
    </b-card>

    <!-- DASHBOARDS -->

    <!-- Horas Mes e Custos -->

    <b-card :title="`Dashboard`" class="text-left">
      <b-row class="mb-4">
        <b-col
          v-if="hourPerMonth_data.render"
          cols="12"
          md="6"
          lg="4"
          class="text-center mx-auto"
        >
          <label>Horas X Mês</label>
          <ProfitabilityChart :chartData="hourPerMonth_data"
        /></b-col>

        <b-col
          v-if="costPerMonth_data.render"
          cols="12"
          md="6"
          lg="4"
          class="text-center mx-auto flex"
        >
          <label>Custos (Direto + Indireto)</label>
          <ProfitabilityChart :chartData="costPerMonth_data"
        /></b-col>
      </b-row>
    </b-card>

    <b-card v-if="hoursPerProject_data.render">
      <b-col class="text-center mx-auto w-10 h-50">
        <label>Horas por projeto</label>
        <ProfitabilityChart :chartData="hoursPerProject_data"
      /></b-col>
    </b-card>

    <b-card v-if="hoursPerProject_data.render">
      <b-col class="text-center mx-auto w-10 h-50">
        <label>Custo por projeto</label>
        <ProfitabilityChart :chartData="costsPerProject_data"
      /></b-col>
    </b-card>
  </div>
</template>

<script>
import {
  VBTooltip,
  BCol,
  BRow,
  BCard,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue';
import ProfitabilityChart from '@/components/Chart.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCol,
    BRow,
    BCard,
    ProfitabilityChart,
    BFormInput,
    BFormGroup,
  },
  data: () => ({
    item: {
      directCost: 0,
      indirectCost: 0,
      totalCost: 0,
      hours: 0,
      projectResumeDashboard: { projectCost: 0 },
    },
    project_name: 'Projeto',
    start_date: '2024-01-01',
    end_date: '2024-02-02',

    hourPerMonth_data: { render: false },
    costPerMonth_data: { render: false },

    hoursPerProject_data: { render: false },
    costsPerProject_data: { render: false },

    chartOptions: {
      chart: {
        id: 'bar',
        type: 'bar',
        stacked: true,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (number) {
            if (isNaN(number)) {
              return 'Invalid input';
            }
            if (number === 0) {
              return 'R$ 0,00';
            }
            if (number < 100) {
              const formattedCents = number.toFixed(2).padStart(5, '0');
              return `0,${formattedCents}`;
            }
            const [integerPart, decimalPart] = (number / 100)
              .toFixed(2)
              .toString()
              .split('.');
            const formattedIntegerPart = integerPart.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            );
            const result = `R$ ${formattedIntegerPart},${decimalPart}`;
            return result;
          },
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            hideOverflowingLabels: true,
            position: 'top',
          },
          columnWidth: 40,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (number) {
          if (isNaN(number)) {
            return 'Invalid input';
          }
          if (number === 0) {
            return 'R$ 0,00';
          }
          if (number < 100) {
            const formattedCents = number.toFixed(2).padStart(5, '0');
            return `0,${formattedCents}`;
          }
          const [integerPart, decimalPart] = (number / 100)
            .toFixed(2)
            .toString()
            .split('.');
          const formattedIntegerPart = integerPart.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ','
          );
          const result = `R$ ${formattedIntegerPart},${decimalPart}`;
          return result;
        },
        offsetY: -20,
        style: {
          fontSize: '10px',
          colors: ['#304758'],
        },
      },
    },

    chartOptions_line: {
      chart: {
        id: 'bar',
        type: 'area',
        stacked: true,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            hideOverflowingLabels: true,
            position: 'top',
          },
          columnWidth: 20,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + '%';
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
    },
  }),

  created() {
    this.getAll();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },

  methods: {
    // Entidades
    async getItem() {
      this.$store
        .dispatch('getProject', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.project_name = resp.project_name;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getAll() {
      this.$store
        .dispatch('getUserDashboard', {
          start_date: this.start_date,
          end_date: this.end_date,
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then(async (resp) => {
          this.getItem();
          await this.setHoursPerMonth(resp.hoursMonth);
          await this.setCostsPerMonth(resp.costsMonth);
          await this.setHoursPerProject(resp.projectHours);
          await this.setCostPerProject(resp.projectCosts);

          this.item = resp;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async setHoursPerMonth(props) {
      const data = [];
      const date = [];
      props.map((e) => {
        data.push(e.hours);
        date.push(this.dateFormatter(e.month, 'MM/yy'));
      });

      this.hourPerMonth_data = {
        render: true,
        chartOptions: {
          ...this.chartOptions,

          yaxis: {
            labels: {
              formatter: function (e) {
                return e + 'h';
              },
            },
          },
          dataLabels: {
            offsetY: -20,
            style: {
              fontSize: '10px',
              colors: ['#304758'],
            },
            formatter: function (e) {
              return e + 'h';
            },
          },
          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Horas no mês',
            data,
          },
        ],
      };
    },

    async setCostsPerMonth(props) {
      const data = [];
      const date = [];
      props.map((e) => {
        data.push(e.cost);
        date.push(this.dateFormatter(e.month, 'MM/yy'));
      });

      this.costPerMonth_data = {
        render: true,
        chartOptions: {
          ...this.chartOptions,

          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Horas no mês',
            data,
          },
        ],
      };
    },

    async setHoursPerProject(props) {
      const data = [];
      const date = [];
      props.map((e) => {
        data.push(e.hours);
        date.push(e.project);
      });

      this.hoursPerProject_data = {
        render: true,
        width: '100%',
        height: 300,
        chartOptions: {
          chart: {
            type: 'bar',
            // height: 350,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '900px',
            },
            dataLabels: {
              maxItems: 2,
              hideOverflowingLabels: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Horas',
            data,
          },
        ],
      };
    },

    async setCostPerProject(props) {
      const data = [];
      const date = [];
      props.map((e) => {
        data.push(e.cost);
        date.push(e.project);
      });

      this.costsPerProject_data = {
        render: true,
        width: '100%',
        height: 300,
        chartOptions: {
          chart: {
            type: 'bar',

            // height: 350,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '100px',
            },
          },
          dataLabels: {
            formatter: function (number) {
              if (isNaN(number)) {
                return 'Invalid input';
              }
              if (number === 0) {
                return 'R$ 0,00';
              }
              if (number < 100) {
                const formattedCents = number.toFixed(2).padStart(5, '0');
                return `0,${formattedCents}`;
              }
              const [integerPart, decimalPart] = (number / 100)
                .toFixed(2)
                .toString()
                .split('.');
              const formattedIntegerPart = integerPart.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              );
              const result = `R$ ${formattedIntegerPart},${decimalPart}`;
              return result;
            },
            enabled: true,
          },
          // yaxis: {
          //   labels: {
          //     formatter: function (number) {
          //       if (isNaN(number)) {
          //         return 'Invalid input';
          //       }
          //       if (number === 0) {
          //         return 'R$ 0,00';
          //       }
          //       if (number < 100) {
          //         const formattedCents = number.toFixed(2).padStart(5, '0');
          //         return `0,${formattedCents}`;
          //       }
          //       const [integerPart, decimalPart] = (number / 100)
          //         .toFixed(2)
          //         .toString()
          //         .split('.');
          //       const formattedIntegerPart = integerPart.replace(
          //         /\B(?=(\d{3})+(?!\d))/g,
          //         ','
          //       );
          //       const result = `R$ ${formattedIntegerPart},${decimalPart}`;
          //       return result;
          //     },
          //   },
          // },
          xaxis: {
            labels: {
              formatter: function (number) {
                if (isNaN(number)) {
                  return 'Invalid input';
                }
                if (number === 0) {
                  return 'R$ 0,00';
                }
                if (number < 100) {
                  const formattedCents = number.toFixed(2).padStart(5, '0');
                  return `0,${formattedCents}`;
                }
                const [integerPart, decimalPart] = (number / 100)
                  .toFixed(2)
                  .toString()
                  .split('.');
                const formattedIntegerPart = integerPart.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                );
                const result = `R$ ${formattedIntegerPart},${decimalPart}`;
                return result;
              },
            },
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Horas',
            data,
          },
        ],
      };
    },
  },
};
</script>
<style scoped>
.dashboard-item {
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  text-align: start;
}
</style>
