import { render, staticRenderFns } from "./DashBoardUsers.vue?vue&type=template&id=3cbd0614&scoped=true"
import script from "./DashBoardUsers.vue?vue&type=script&lang=js"
export * from "./DashBoardUsers.vue?vue&type=script&lang=js"
import style0 from "./DashBoardUsers.vue?vue&type=style&index=0&id=3cbd0614&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cbd0614",
  null
  
)

export default component.exports